var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReleaseNotes" },
    [
      _c(
        "div",
        {
          staticClass: "row row--align-center row--justify-between mb-30 mt-30"
        },
        [
          _c("h1", { staticClass: "ReleaseNotes__header" }, [
            _vm._v("Bobtail Releases")
          ]),
          _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
            _vm.selectedTag
              ? _c("div", { staticClass: "row row--width-auto" }, [
                  _c(
                    "label",
                    { staticClass: "fc-dark fs-14 fw-med mr-3 capitalize" },
                    [
                      _vm._v("Selected Tag:\n          "),
                      _c("span", { staticClass: "fs-14" }, [
                        _vm._v(_vm._s(_vm.selectedTag))
                      ])
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "fc-dark fs-14",
                      on: {
                        click: function($event) {
                          _vm.selectedTag = null
                        }
                      }
                    },
                    [
                      _vm._v("\n          (\n          "),
                      _c("span", { staticClass: "fs-14 underline" }, [
                        _vm._v("remove")
                      ]),
                      _vm._v("\n          )\n        ")
                    ]
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._l(_vm.filteredReleases, function(release, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "ReleaseNotes__release-item column mb-15"
          },
          [
            _c(
              "div",
              {
                staticClass: "ReleaseNotes__top-container clickable",
                on: {
                  click: function($event) {
                    release.expanded = !release.expanded
                  }
                }
              },
              [
                _c("label", { staticClass: "fs-18 fw-med" }, [
                  _vm._v(_vm._s(_vm._f("datetime")(release.date, "MM-DD-YYYY")))
                ]),
                _c(
                  "div",
                  { staticClass: "row row--wrap" },
                  _vm._l(release.tags, function(tag, tagIndex) {
                    return _c(
                      "button",
                      {
                        key: tagIndex,
                        staticClass:
                          "fc-light fs-14 capitalize mr-10 underline",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.selectedTag = tag
                          }
                        }
                      },
                      [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                    )
                  }),
                  0
                )
              ]
            ),
            _c("transition", { attrs: { name: "Transition__fade" } }, [
              release.expanded
                ? _c("div", { staticClass: "ReleaseNotes__hidden-content" }, [
                    release.bugFixes.length
                      ? _c("div", { staticClass: "column" }, [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "ReleaseNotes__section-header fs-20 fw-med"
                            },
                            [_vm._v("Bug Fixes")]
                          ),
                          _c("hr", {
                            staticClass: "ReleaseNotes__section-divider"
                          }),
                          _c(
                            "ul",
                            { staticClass: "ReleaseNotes__list" },
                            _vm._l(release.bugFixes, function(bug, bugIndex) {
                              return _c(
                                "li",
                                {
                                  key: bugIndex,
                                  staticClass: "ReleaseNotes__list-item fs-16"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(bug.text) +
                                      "\n              "
                                  ),
                                  bug.link && _vm.isMaster
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "fc-light fs-14",
                                          attrs: {
                                            href: bug.link,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v("\n                ("),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fc-light fs-14 underline"
                                            },
                                            [_vm._v("Ticket")]
                                          ),
                                          _vm._v(")\n              ")
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    release.newFeatures.length
                      ? _c("div", { staticClass: "column" }, [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "ReleaseNotes__section-header fs-20 fw-med"
                            },
                            [_vm._v("New Features")]
                          ),
                          _c("hr", {
                            staticClass: "ReleaseNotes__section-divider"
                          }),
                          _c(
                            "ul",
                            { staticClass: "ReleaseNotes__list" },
                            _vm._l(release.newFeatures, function(
                              feature,
                              featureIndex
                            ) {
                              return _c(
                                "li",
                                {
                                  key: featureIndex,
                                  staticClass: "ReleaseNotes__list-item fs-16"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(feature.text) +
                                      "\n              "
                                  ),
                                  feature.link && _vm.isMaster
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "fc-light fs-14 underline",
                                          attrs: {
                                            href: feature.link,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v("\n                ("),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fc-light fs-14 underline"
                                            },
                                            [_vm._v("Ticket")]
                                          ),
                                          _vm._v(")\n              ")
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    release.updates.length
                      ? _c("div", { staticClass: "column" }, [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "ReleaseNotes__section-header fs-20 fw-med"
                            },
                            [_vm._v("Updates")]
                          ),
                          _c("hr", {
                            staticClass: "ReleaseNotes__section-divider"
                          }),
                          _c(
                            "ul",
                            { staticClass: "ReleaseNotes__list" },
                            _vm._l(release.updates, function(
                              update,
                              updateIndex
                            ) {
                              return _c(
                                "li",
                                {
                                  key: updateIndex,
                                  staticClass: "ReleaseNotes__list-item fs-16"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(update.text) +
                                      "\n              "
                                  ),
                                  update.link && _vm.isMaster
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "fc-light fs-14 underline",
                                          attrs: {
                                            href: update.link,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v("\n                ("),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fc-light fs-14 underline"
                                            },
                                            [_vm._v("Ticket")]
                                          ),
                                          _vm._v(")\n              ")
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }