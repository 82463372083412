<template>
  <div class="ReleaseNotes">
    <div class="row row--align-center row--justify-between mb-30 mt-30">
      <h1 class="ReleaseNotes__header">Bobtail Releases</h1>
      <transition name="Transition__opacity-fade">
        <div
          v-if="selectedTag"
          class="row row--width-auto"
        >
          <label class="fc-dark fs-14 fw-med mr-3 capitalize">Selected Tag:
            <span class="fs-14">{{ selectedTag }}</span>
          </label>
          <button
            @click="selectedTag = null"
            class="fc-dark fs-14"
          >
            (
            <span class="fs-14 underline">remove</span>
            )
          </button>
        </div>
      </transition>
    </div>
    <div
      v-for="(release, index) in filteredReleases"
      :key="index"
      class="ReleaseNotes__release-item column mb-15"
    >
      <div
        @click="release.expanded = !release.expanded"
        class="ReleaseNotes__top-container clickable"
      >
        <label class="fs-18 fw-med">{{ release.date | datetime('MM-DD-YYYY') }}</label>

        <!-- TAGS -->
        <div class="row row--wrap">
          <button
            v-for="(tag, tagIndex) in release.tags"
            :key="tagIndex"
            @click.stop="selectedTag = tag"
            class="fc-light fs-14 capitalize mr-10 underline"
          >
            {{ tag }}
          </button>
        </div>
      </div>

      <!-- HIDDEN CONTENT -->
      <transition name="Transition__fade">
        <div
          v-if="release.expanded"
          class="ReleaseNotes__hidden-content"
        >
          <!-- BUGS -->
          <div
            v-if="release.bugFixes.length"
            class="column"
          >
            <h2 class="ReleaseNotes__section-header fs-20 fw-med">Bug Fixes</h2>
            <hr class="ReleaseNotes__section-divider">
            <ul class="ReleaseNotes__list">
              <li
                v-for="(bug, bugIndex) in release.bugFixes"
                :key="bugIndex"
                class="ReleaseNotes__list-item fs-16"
              >
                {{ bug.text }}
                <a
                  v-if="bug.link && isMaster"
                  class="fc-light fs-14"
                  :href="bug.link"
                  target="_blank"
                >
                  (<span class="fc-light fs-14 underline">Ticket</span>)
                </a>
              </li>
            </ul>
          </div>

          <!-- NEW FEATURES -->
          <div
            v-if="release.newFeatures.length"
            class="column"
          >
            <h2 class="ReleaseNotes__section-header fs-20 fw-med">New Features</h2>
            <hr class="ReleaseNotes__section-divider">
            <ul class="ReleaseNotes__list">
              <li
                v-for="(feature, featureIndex) in release.newFeatures"
                :key="featureIndex"
                class="ReleaseNotes__list-item fs-16"
              >
                {{ feature.text }}
                <a
                  v-if="feature.link && isMaster"
                  class="fc-light fs-14 underline"
                  :href="feature.link"
                  target="_blank"
                >
                  (<span class="fc-light fs-14 underline">Ticket</span>)
                </a>
              </li>
            </ul>
          </div>

          <!-- UPDATES -->
          <div
            v-if="release.updates.length"
            class="column"
          >
            <h2 class="ReleaseNotes__section-header fs-20 fw-med">Updates</h2>
            <hr class="ReleaseNotes__section-divider">
            <ul class="ReleaseNotes__list">
              <li
                v-for="(update, updateIndex) in release.updates"
                :key="updateIndex"
                class="ReleaseNotes__list-item fs-16"
              >
                {{ update.text }}
                <a
                  v-if="update.link && isMaster"
                  class="fc-light fs-14 underline"
                  :href="update.link"
                  target="_blank"
                >
                  (<span class="fc-light fs-14 underline">Ticket</span>)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable quotes */
export default {
  name: 'ReleaseNotes',

  created () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      if (user.employee) {
        if (user.employee.is_master) this.isMaster = true
      }
    }

    // Expand most recent update (I don't want to manually update the expanded property
    // each release
    this.$set(this.releases[0], 'expanded', true)
  },

  data () {
    return {
      isMaster: false,
      /*
       * A single set of release notes contains:
       * New Features
       * Updates
       * Bug Fixes
       *
       * Each list item MAY HAVE an associated link to the ZenHub or GitHub PR.
       *
       * Each release has a set of tags that help give an idea of what was touched by the
       * release.
       *
       * Each release MAY HAVE an introduction.
       *
       * Each release MAY HAVE an outroduction.
       *
       * Each release has a date.
       */
      releases: [
        // Newest to Oldest
        {
          date: new Date(2021, 2, 31),
          expanded: false,
          tags: [

          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://bobtail.atlassian.net/browse/BD-7',
              text: `We found a case where the calculation for a client's factor fee amount when approving an invoice would limit an employee from deducting the full amount by $0.01 (one penny), which would cause a balance discrepancy of $0.01. This meant the client wasn't getting the accurate amount of money ($0.01 less/more). We've written tens of test cases to be used for validating the correct calculation of the factor fee amount and are confident this issue has been resolved.`
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2448',
              text: `We removed ' from the allowed characters in an uploaded file name to prevent that file from being skipped over.`
            },
          ],
          newFeatures: [
            {
              link: 'https://bobtail.atlassian.net/browse/BD-9',
              text: `You can now search approved invoices by invoices' flag status, such as "other" or "paid to client". You'll find this select option within the hidden filters in approved invoices.`
            },
            {
              link: 'https://bobtail.atlassian.net/browse/BD-49',
              text: `You can now apply a non-flag changing invoice update to all types of invoices: "note." This will apply an invoice update to an invoice without actually updating the status of the invoice. Additionally, this will be now be at the top of the list of update options for every type of invoice.`
            },
          ],
          updates: [
            {
              link: 'https://bobtail.atlassian.net/browse/BD-10',
              text: `Clients can now see the memo they set on an invoice in the list of invoices that make up each of their transfers in their transfers dashboard.`
            },
            {
              link: 'https://bobtail.atlassian.net/browse/BD-11',
              text: `Clients cannot view their activity page.`
            },
          ],
        },
        {
          date: new Date(2021, 2, 3),
          expanded: false,
          tags: [
            'PDF',
          ],
          intro: "HUGE update! We finally took some time to address what was reported as the most time-consuming, frustrating issue plaguing our account managers -> PDFs not being complete with all the files uploaded within an invoice. We apologize for the inconvenience up until this point, but we believe we've resolved these issues for you!",
          outro: "If you do notice anything funny or incorrect, please feel free to reach out to Frank and Chris!",
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2345',
              text: `Fixed images not being reliably attached to PDFs. The issue was related to convertAPI (a service we use to convert images to PDF-looking scans) not handling specific special characters in images, which would cause the service to skip over that upload in the PDF generation progress.`
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2345',
              text: `The regenerate PDF button option in the "more" section of the footer of pending, approved, paid, and declined invoice items has been fixed to properly regenerate images attached in the invoice. This was related to the bug fix above (convertAPI + special characters).`
            },
          ],
          newFeatures: [],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2307',
              text: `Investigated and resolved issue(s) with uploading an image via Filestack's Camera option.`
            },
          ],
        },
        {
          date: new Date(2021, 2, 24),
          expanded: false,
          tags: [
            'adjustments',
            'buyouts',
            'client profile',
            'credit rating',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2244',
              text: `You can no longer make a "release of funds" adjustment for a client that doesn't have an active bank.`
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2263',
              text: `We fixed an issue that was causing low credit rating debtors to not be auto-flagged on invoices.`
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1897',
              text: `We fixed an issue preventing buyouts from being displayed if you were searching for a days outstanding greater than 29.`
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2375',
              text: `We fixed an issue where the bank accounts in a client's activity page wouldn't change when you changed the client via universal search.`
            },
          ],
          newFeatures: [],
          updates: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2379',
              text: `You will only see account manager and salesperson employees when selecting an account manager or sales rep for a client in their account section.`
            },
          ],
        },
        {
          date: new Date(2021, 2, 19),
          expanded: false,
          tags: [
            'twilio',
            'environment'
          ],
          intro: null,
          outro: null,
          bugFixes: [],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2317',
              text: `At the request of our sales team, we created a sales environment to allow sales live demo the application to users, which reportedly drives conversion through the roof. The sales demo can be found at: https://demo-app.bobtailtest.com/`
            }
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2288',
              text: `We updated the database that the broker search via texting a debtor's MC points to, which also fixed that functionality from previously being broken.`,
            },
          ],
        },
        {
          date: new Date(2021, 1, 5),
          expanded: false,
          tags: [
            'user-management',
          ],
          intro: null,
          outro: null,
          bugFixes: [],
          newFeatures: [],
          updates: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2214/files',
              text: `Search options in user-management are no longer "automatic," which means you'll have to click the "search" button to update the results. Additionally, we've made "MC" search for debtors a strict search (requires exact matches to see results).`,
            },
          ],
        },
        {
          date: new Date(2021, 0, 21),
          expanded: false,
          tags: [
            'user-management',
            'invoice search',
            'emails',
            'invoice approve',
            'invoice notes',
            'reports',
            'maintenance',
            'banking',
            'PDF',
            'client docs',
            'balances',
            'credit rating',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2283',
              text: `Fixed an issue where the search results label ("100 Total ($7,305.95)") wasn't reporting the accurate number of invoices for the current search criteria, which was also affecting the pagination component.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2252',
              text: `We found an issue where some NOA attachments weren't providing a preview in the email sent out, which was related to the lack of a ".pdf" extension on the attached file.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1902',
              text: `Under the right situation, when searching for an MC # in user-management (debtors), the incorrect number of results were being returned, which made it seem like there should be results but you couldn't view them on any page.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1823',
              text: `To prevent setting unfair expectations for the clients, we're no longer allowing an invoice to be approved if that client of the invoice doesn't have a bank account matching the requirements of the invoice. For example, if a client doesn't have a bank that can accept wire routing numbers, you won't be able to approve a wire invoice for that client.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2104',
              text: `We've included an addition to invoices: a general note (for employees and clients) and a memo (for clients only). This will allow you to attach a note to an invoice to help with processing or keeping track of an important detail. Memos provide clients the ability to save a small note along with an invoice, such as who drove the load. You can edit the note the same way you edit an invoice, and the note will be displayed on the invoice PDF. Memos are not visible to employees.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2202',
              text: `Although not exposed anywhere in the application, we now have the ability to create an annual report for individual clients, which was used to send reports to each client at the beginning of the year for tax purposes.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2166',
              text: `Due to the complexity and scale of Bobtail as an application, we often need to perform updates where we can't have any existing data being changed while an update is in progress, so we built a maintenance system to automtically make the site unavailable. This isn't something that will be used very often, but when it is in place, you'll find that you can only see one page (maintenance page) and when maintenance is done, your page will automatically be updated.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1824',
              text: `When viewing a client's banking page, you can now click "learn more" in the text above their banks to display a helpful modal explaining all the current states of their bank accounts. In other words, if they have a bank account requiring a wire routing number, they'll see an example bank matching that with an explanation of what it means to require a wire routing number.`,
            },
          ],
          updates: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2214/files',
              text: `Search options in user-management are no longer "automatic," which means you'll have to click the "search" button to update the results. Additionally, we've made "MC" search for debtors a strict search (requires exact matches to see results).`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1905',
              text: `We updated the formatting of the invoice PDF - "Invoice #" will now stand out more and we added the legal name and all applicable DBAs for client and debtors.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2098',
              text: `Client Docs now has an additional optional document to be uploaded: "MC Authority".`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2021',
              text: `In the Bobtail dashboard, we updated the balances table to include a new column with how a balance has changed in the last 24 hours. If the number is red, it indicates a negative balance, and vice versa for a green number.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2261',
              text: `Bank accounts will no longer show an "activate" button if they require some sort of verification, such as micro-deposits or a wire routing number. This should simplify the banking experiencing by limiting options and forcing clients into a specific order of operations for adding and activating a bank.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2253',
              text: `Bank accounts owner names can only include letters and spaces. No special characters or numbers will be allowed.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2212',
              text: `When displaying the last week's chargebacks in the sidebar while approving an invoice, we are also displaying the day of the week alongside the date and amount of the chargeback.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2211',
              text: `When a credit rating is changed, it will display the name of the user who made the change in the note section.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1848',
              text: `When a client adds a wire routing number to a bank requiring a wire routing number, they'll now see a verification message when they input their routing number. If a match is found, they can save it and update their bank. If we don't see a relationship between their wire routing number and a bank, we don't allow them to save it since we couldn't send wires to that number.`,
            },
          ],
        },
        {
          date: new Date(2021, 0, 21),
          expanded: false,
          tags: [
            'banking',
            'balance',
            'base-input',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2268',
              text: `Updated client's balance dashboard to sort the balance history by created at instead of updated at.`,
            },
          ],
          newFeatures: [],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2259',
              text: `Clients will no longer see "add/edit name on account" buttons on their bank accounts if the bank doesn't have completed verification (i.e. verified micro-deposits, wire routing number, etc.). This will eliminate clutter and simplify the experience.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2217',
              text: `Every input is now trimmed in the template via "v-model.trim".`,
            },
          ],
        },
        {
          date: new Date(2021, 0, 20),
          expanded: false,
          tags: [
            'client search input',
            'debtor search input',
            'debtor-profile',
            'email',
            'invoice approve',
            'invoice updates',
            'processing notes',
            'transfers',
            'user-management',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2082',
              text: `Fixed a bug where you could overwrite a debtor's information by editing a debtor's profile section then changing debtors via universal debtor search without closing the section you were editing and then saving the section once you've loaded the new debtor.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2097',
              text: `In Bobtail's transfers dashboard, the search functionaliy for the transfers table has been fixed.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2097',
              text: `In Bobtail's transfers dashboard, we fixed some issues with employees trying to expedite a wire.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2089',
              text: `Added search for employees in user-management. You can now search by name.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2092',
              text: `You can now click outside of the input and results of the client and debtor search inputs to reset the input to it's last selected value or empty, whichever was most recent.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2066',
              text: `We've added the ability to create processing notes for clients and debtor. These notes will be displayed when processing an invoice with a client and debtor that has a general or specific note.`,
            },
          ],
          updates: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2206',
              text: `At request of employees, I'm removing "/" from the blacklisted characters on missing and notes for making an item update.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/1727',
              text: `A client's company email will now match the email they login with. This should reduce confusion when sending a reset password email to a client and expecting it to go to the company email.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2097',
              text: `In Bobtail's transfers dashboard, we moved the expedite wires button into the individual wires so you can expedite wires individually in case there were more than one. Additionally, we did the same thing with the "initiate ACH" button; although, there should never be more than one ACH transfer. We just wanted the design to stay consistent.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2097',
              text: `In Bobtail's transfers dashboard, you can now click on clients' names to go to their activity page.`,
            },
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2018',
              text: `When approving an invoice (not an auto-approval), you'll now be able to see the last week's chargebacks for that client.`,
            },
          ],
        },
        {
          date: new Date(2021, 0, 14),
          expanded: false,
          tags: [
            'paid search',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://github.com/fs-bobtail/bobtail/pull/2186',
              text: `Found a bug causing paid search to fail repeatedly due to the offset being -25 (negative). We switched from tracking the offset in the URL to the page number to ensure we can reload or navigate back to the correct page of results in paid. By using offset, it was possible to read an offset of "0" then when performing a search, subtracting 1 and multiplying by 25, which is how we usually get the offset knowing the page should start at 1 not 0.`,
            },
          ],
          newFeatures: [],
          updates: [],
        },
        {
          date: new Date(2021, 0, 11),
          expanded: false,
          tags: [
            'UUIDs',
            'invoice updates',
          ],
          intro: "We converted every aspect of IDs in our application to UUIDs, which involved a very stressful, and broken, release day, but overall, this is a system that allows us to move further without many of the previous limitations that our previous ID system had.",
          outro: null,
          bugFixes: [],
          newFeatures: [],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1268',
              text: `We've updated all IDs throughout the application from a numerical to a unique ID system. Yay!`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2171',
              text: `When making a "payment" or "paid to client" invoice update on an approved invoice, the "Check #" or "ACH" and "Payment Date" will now display above the update note.`,
            },
          ],
        },
        {
          date: new Date(2020, 11, 14),
          expanded: false,
          tags: [
            'buttons',
            'client profile',
            'client status',
            'invoice-details',
            'invoice items',
            'invoice updates',
            'paid invoices',
            'transfers',
            'user-management',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1446',
              text: `We fixed an issue where you wouldn't be able to navigate to the next invoice(s) in invoice-details after you clicked on a load # of a pending invoice in a client's activity page.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2040',
              text: `When you approved, declined, or delete an invoice you searched for using universal search, you will now be taken automatically back to pending invoices... instead of it breaking and not allowing you to do anything until you refresh (even though we know how very, very fun that is).`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1895',
              text: `We fixed a use case where "null" would be displayed for the notes of a status change if you updated a client's status twice in a row.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1804',
              text: `After load numbers exceed 10 characters, the number will be truncated with an ellipsis. However, if you hover your mouse over the number, a tooltip will popup displaying the full number.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2057',
              text: `We fixed an issue with invoices being sorted incorrectly in paid invoices, which made it appear as if there were only 2 months of invoices being reported instead of the 6 (default).`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1914',
              text: `We fixed an issue with an improper number of pages for a client's transfers in their activity page.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1915',
              text: `Due to an issue updating a user's zip code, we updated all applicable fields in a client or debtor's profile to automatically get rid of any spaces left at the beginning or end of an input.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2031',
              text: `One of our most common errors was when someone would try to apply an invoice update, it would fail, and it was because the invoice no longer had the same status due to another employee having updated the invoice. Now, whenever you add an invoice update, the invoice status is first checked and if it's no longer the same, you will see a Toastr message with the details of who updated the message and why.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1917',
              text: `You can now update an employees application status when editing their user.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1966',
              text: `We've updated the available options in each invoice footer to have a "more" option, which now contains the "undo," "delete," and the newest function, "regenerate PDF." When "more" is clicked on, new options will be displayed in the invoice footer, as well as "back" option. Regenerate PDF is available in the case where the PDF didn't build correctly.`,
            },
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2072',
              text: `We've updated all the crucial buttons in the application to be "unclickable" while its respected operation is running. This prevents any accidental double requests.`,
            },
          ],
        },
        {
          date: new Date(2020, 11, 3),
          expanded: false,
          tags: [
            'approved invoices',
            'authentication',
            'invoice updates',
            'invoice create and edit',
            'sentry',
            'logo',
            'balances',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1980',
              text: `We fixed an issue where the "days outstanding" filters were showing the incorrect invoices.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2019',
              text: `In an attempt to make the login experience and your guys' work experience a bit smoother, we've tighten up the way we check emails before saving them. The goal is to ensure clients sign in with more ease. An example of what we solved is you could previous save an email like so, "thisismyemail@gmail.com ", so when the user tried to login without the space at the end of the email, it would require you guys to do a password reset for that user. This is just one example.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2030',
              text: `I noticed a very, very common error that was occuring among the employees when trying to add an update to an invoice: the invoice's status had been updated by another employee since you had initially loaded the invoice; therefore, when you go to perform adding an update, a check is now made to ensure that invoice is still the same status. If it is not, you'll see a Toastr message at the top of the page explaining the invoice status has been changed by X employee.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2023',
              text: `Clients will now see an alert in the bottom of the component that allows them to create or edit invoices giving them a warning that incomplete or missing paperwork may affect getting money transferred for the invoice.`,
            },
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2025',
              text: `We updated our error reporting service to help us gather more accurate information when errors occur.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1859',
              text: `Our logo in the top left corner is now a link back to pending invoices.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2023',
              text: `We updated the label of the debtor search input when creating or editing an invoice to remind users they can also search by MC #.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2036',
              text: `When you're making a balance adjustment to a client, you can now use commas to enter the value amounts.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1921',
              text: `You will now see a separate label for the payment date on a payment invoice update, as well as the separate label for the Check # or ACH. We hope this makes finding the specific information much quicker and easier.`,
            },
          ],
        },
        {
          date: new Date(2020, 10, 30),
          expanded: false,
          tags: [
            'authentication',
            'client profile',
            'notifications',
            'shortpays',
            'transfers'
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1911',
              text: `We fixed some inconsistency with resetting passwords.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1911',
              text: `We had a few issues with clients attempting to sign-in with their emails in something other than all lowercase, such as "paulIsTheBest" instead of "paulisthebest." We save emails in lowercase, so we fixed the login to allow any sort of casing for letters. Hopefully, you'll see less support requests for logging in.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1838',
              text: `When a client's invoice is shortpaid, the client's contacts (with "on" notifications) and the company will be notified of the shortpay.`,
            },
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1862',
              text: `When setting the account manager for a client, only account manager type employees will show up in the dropdown options.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1949',
              text: `When calculating the estimated transfer date in our transfer tables and text messages, we're now using business days to calculate the date, which takes into consideration holidays.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1830',
              text: `When expanding a transfer in the Bobtail transfers dashboard, you will now be able to see who was responsible for expediting a wire or initiating an ACH transfer.`,
            },
          ],
        },
        {
          date: new Date(2020, 10, 23),
          expanded: false,
          tags: [
            'invoice items',
            'transfers',
          ],
          intro: null,
          outro: null,
          bugFixes: [],
          newFeatures: [],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1828',
              text: `We added a new section to the Bobtail “transfers” dashboard: upcoming ACH transfers. You can click on a row to see what clients make up the transfer.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1904',
              text: `You will now see a status indicator for all clients and debtors on their profile pages next to their name (previously only visible for brokers)`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1909',
              text: `On invoice items, only the NAME of the client and debtor will be clickable. The Phone # will no longer be apart of that clickable area.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1909',
              text: `On invoice items, we added the client’s phone # under their name (like the debtors).`,
            },
          ],
        },
        {
          date: new Date(2020, 10, 19),
          expanded: false,
          tags: [
            'assignments',
            'balances',
            'PDF',
            'universal search',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1855',
              text: `For awhile, we had a problem of trying to share (email) an invoice when the invoice PDF was larger than 10MB. Now, you'll be notified that you need to manually send the PDF when applicable. Also, if an email fails to send, a invoice update will be created for that invoice.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1829',
              text: `We've really updated the assignments page. We are added notifications for when the NOA email was sent (when an invoice is approved), so you'll be able to check when that email was actually sent out by expanding an assignment. Additionally, you can now manually resend the NOA email on a "verified" assignment. Simply expand the invoice and "Email NOA."`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1934',
              text: `Also, assignment categories now have their own pagination, which means you can change pages of verified assignments while not changing the assignments in pending or released, and when you switch between the tabs, you'll always be at page one of that type of assignment. This means no more change numbers in each tab when you change pages.`,
            },
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1794',
              text: `When you search for a load or ID in universal search, each category (pending, approved, declined, and paid) will have it's own pagination component, which means you can paginate only one category instead of seeing 25 results divided up per page.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1887',
              text: `We've removed the balances' "add adjustment" button from a client's activity page. The only place this will now be possible is their balances dashboard page.`,
            },
          ],
        },
        {
          date: new Date(2020, 9, 21),
          expanded: false,
          tags: [
            'approved search',
            'balances',
            'client profile',
            'invoice updates',
            'user-management',
            'paid invoices',
          ],
          intro: null,
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1796',
              text: `When searching and changing pages in user-management, you can now go to a client or debtor's profile, then click the browser's back button to go back to the same page you were on when you left. In other words, if you were on page 5, clicked on a client, then clicked back, you'll still be on page 5.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1883',
              text: `Yesterday, we applied a hotfix fixing a major bug with applying a balance adjustment. The possibility of the error has been fix, but we're still combing through the adjustments to find errors and manually fixing them in the DB.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1788',
              text: `View in paid invoices now works.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1752 https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1802',
              text: `We separated the query search in approved invoices into a strict load # and invoice ID search. This means you have to search for the EXACT load # or ID to find your result.`,
            },
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1799',
              text: `We added the company phone number under the client's name in the clients' account pages.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1858',
              text: `"Payment" invoice updates now have a label that will tell you exactly what the check # is.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1837',
              text: `"missing" invoice updates will now have a label that will tell you exactly what is missing.`,
            },
          ],
        },
        {
          date: new Date(2020, 9, 16),
          expanded: false,
          tags: [
            'balances',
            'banking',
            'buyouts',
            'clean invoices',
            'deleting invoices',
            'email',
            'flags',
            'last chargeback',
            'PDF',
            'page title',
            'payment plan',
            'plaid',
            'transfer type',
            'verifications',
          ],
          intro: "In the upcoming release, we're updating a lot of features, fixing many, many bugs, and including a few requests where we could squeeze them in.",
          outro: null,
          bugFixes: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1400',
              text: `We fixed an issue that prevented pending invoices from being deleted. What we found is invoices that were approved, then undone, and then delete would fail the request.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1775',
              text: `You can no longer send an email via the "share" invoice functionality for an invoice that is a buyout.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1732',
              text: `We updated/fixed the sorting for Bobtail dashboard balances to sort from the largest negative number to the largest positive number.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1784',
              text: `Fixed an occassional discrepancy between what a client's balance was at the top of their profile page and what their last balance amount was in their balance history.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1738',
              text: `Zero date and payment plan notes will now display correctly in the Bobtail dashboard balances.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1738',
              text: `Fixed issue with the sorting on Bobtail dashboard balances. "All" now... works. "Positive" sorts by descending order. "Negative" and "All" sorts by ascending order.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1836',
              text: `(This may take adjustment to perfect) All documents in the invoice PDF should be the same size now. No more mixing of sizes and orientations.`,
            },
          ],
          newFeatures: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1752 https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1745',
              text: `You can now save multiple emails for NOA email(s) and invoice delivery emails by separating the email values with ", " (comma and a space).`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1759',
              text: `With the addition of ^^^, when you go to share an invoice, the input will automatically be filled with all of a debtor's invoice delivery emails, and it will send the invoice to all of the emails.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1751',
              text: `As you navigate the website with multiple tabs open, the browser tab text will tell you what page the tab is on. So, if you're switching between tabs, this will help you navigate to the correct tabs.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1737',
              text: `If you view an invoice details page with a client that is NOT active, you'll see a yellow "not active" flag above any existing flags in the left sidebar.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1700',
              text: `(this will take some adjusting to perfect) We implemented a tool to take a JPG/JPEG uploaded file and essentially scan the image and only save the scanned portion; therefore, if someone takes a picture of a document on their desk, it will crop out the desk portion and only save the document.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1738',
              text: `Added the total amount of Bobtail dashboard balances in a label above the balances as we do on all the invoices pages.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1754',
              text: `Under a client's "payment plan" section, we've added a "last chargeback" section where you can find the last chargeback, the date it occurred, and the amount.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1755',
              text: `If you approve an invoice that update a client's balance to 0, it will clear their payment plan.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1736 https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1749',
              text: `Debtors now have a pay status email, but we're not doing anything with the actual saved values. It's for your record only.`,
            },
          ],
          updates: [
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1299',
              text: `When you update a client's "verifications" setting in their profile, it will trigger an operation that will update all their invoices in pending to reflect their verification status. Please note, the invoice updates will not be immediate. Depending on the current demand on the site, this could take a few seconds to many minutes.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1699',
              text: `Buyout invoices will now only be visible to master level employees, so you'll no longer see it in your "invoices" navigation options.`,
            },
            {
              link: null,
              text: `We've made small improvements to the time it takes the PDF to build when you update an invoice.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1730',
              text: `"Clean" invoices in pending will not include invoices with inactive clients.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1738',
              text: `We've removed the "last chargeback, last invoice, and dilution rate" columns from Bobtail dashboard balances.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1706',
              text: `When you bypass the verification for an invoice, it will be returned in "clean" invoices if you don't approve the invoice.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1497',
              text: `If the first bank a client adds requires manual verification, such as Capitol One, the bank will automatically become their "active" once they verify the micro-deposits.`,
            },
            {
              link: null,
              text: `Plaid is hoping to have their issues with Capitol One fixed by the end of the year. This is 100% out of our control.`,
            },
            {
              link: 'https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1637',
              text: `If an invoice with a wire transfer type is processed between the last wire time (4:50PM) and the last ACH transfer of the day (8PM), then the transfer type is automatically changed to ACH. This ensures the transfer can still make it out by the end of the day.`,
            },
          ],
        },
      ],
      selectedTag: null,
    }
  },

  computed: {
    filteredReleases () {
      let filteredReleases
      if (!this.selectedTag) filteredReleases = this.releases
      else {
        filteredReleases = this.releases.filter(release => release.tags.includes(this.selectedTag))
      }
      // Sort youngest to oldest
      return filteredReleases.sort((a, b) => b.date.getTime() - a.date.getTime())
    },
  },
}
</script>

<style lang="sass">
.ReleaseNotes
  max-width: rem(1024px)
  margin: 0 auto
  width: 100%

  &__hidden-content
    background-color: $background
    border-top: $border
    padding: rem(0px) rem(27px)

  &__list-item
    margin-top: rem(10px)

  &__release-item
    border: $border

  &__section-divider
    margin-bottom: rem(23px) // Matches padding of section-header
    width: 100%

  &__section-header
    padding: rem(23px) 0

  &__top-container
    padding: rem(19px) rem(27px)
    width: 100%
</style>